import request from '@/utils/request';
import { base_url } from '@/utils/BASE_URL';
const server = base_url.server;



// 查询指定时间内某个站点有效的定时任务
function getTimerListByTimeAndSID(startDate, endDate, siteID) {
  return request({
    url: `${server}/${base_url.core}/cronmissions/validlist?startDate=${startDate}&endDate=${endDate}&siteID=${siteID}`,
    method: 'get'
  })
}


// 查询指定时间内某个站点已经执行的任务
function getHistoryListByTimeAndSID(siteID = '', startDate, endDate) {
  return request({
    url: `${server}/${base_url.core}/flightschedules/listByDates?startDate=${startDate}&endDate=${endDate}&siteID=${siteID}`,
    method: 'get'
  })
}

// 定时任务分页
function _getTimerList({ page = 1, size = 10, siteID = '', mode = 0, startDate = 0, endDate = 0 }) {
  return request({
    url: `${server}/dataCenter/qcp/cronmissions/list?page=${page}&size=${size}&mode=${mode}&siteID=${siteID}&startDate=${startDate}&endDate=${endDate}`,
    method: 'get'
  })
}
function getTimerList(page = 1, size = 10, siteID = '', mode = 0, startDate = 0, endDate = 0) {
  return request({
    url: `${server}/dataCenter/qcp/cronmissions/list?page=${page}&size=${size}&mode=${mode}&startDate=${startDate}&endDate=${endDate}`,
    method: 'get'
  })
}

function addTimer(data) {
  return request({
    url: `${server}/${base_url.core}/cronmissions/add`,
    method: 'post',
    data
  })
}

function updateTimer(data) {
  return request({
    url: `${server}/${base_url.core}/cronmissions/update`,
    method: 'put',
    data
  })
}

function deleteTimer(id, excuteTime) {
  let url = `${server}/${base_url.core}/cronmissions/delete?CMID=${id}`
  if (excuteTime) {
    url = `${url}&excuteTime=${excuteTime}`
  }
  return request({
    url: url,
    method: 'delete'
  })
}

function activeTimer(id, excuteTime) {
  let url = `${server}/${base_url.core}/cronmissions/active?CMID=${id}&excuteTime=${excuteTime}&isDisabled=0`
  return request({
    url: url,
    method: 'patch'
  })
}

function handleEnablePlanMission(CMID, excuteTime, isDisabled) {
  return request({
    url: `${server}/${base_url.core}/cronmissions/active?CMID=${CMID}&excuteTime=${excuteTime}&isDisabled=${isDisabled}`,
    method: 'patch',
  })
}

function getCronmissions(sId = '') {
  return request({
    url: `${server}/${base_url.core}/cronmissions/cd/list?siteID=${sId}`,
    method: 'get',
  })
}


export {
  _getTimerList,
  getTimerList,
  getTimerListByTimeAndSID,
  getHistoryListByTimeAndSID,
  addTimer,
  updateTimer,
  deleteTimer,
  activeTimer,
  handleEnablePlanMission,
  getCronmissions
}
