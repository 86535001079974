import request from '@/utils/request';
import { base_url } from '@/utils/BASE_URL';
const server = base_url.server;



function getMissionDetail(id = '') {
  return request({
    url: `${server}/${base_url.core}/missions/missioninfo?missionID=${id}`,
    method: 'get',
  });
}

function getMissionBySiteId(siteId = '') {
  return request({
    url: `${server}/dataCenter/qcp/missions/list?page=1&size=10000&siteID=${siteId}`,
    method: 'get',
  });
}

function insertMission(data) {
  return request({
    url: `${server}/${base_url.core}/missions/add`,
    method: 'post',
    data,
  });
}

function updateMission(data) {
  return request({
    url: `${server}/${base_url.core}/missions/update`,
    method: 'put',
    data,
  });
}

function undockMission(id) {
  return request({
    url: `${server}/${base_url.core}/missions/delete?missionID=${id}`,
    method: 'delete',
  });
}

function sendMission(data) {
  return request({
    url: `${server}/${base_url.core}/uavaction/missionadd`,
    method: 'post',
    data,
  });
}

export { getMissionDetail, insertMission, updateMission, getMissionBySiteId, undockMission, sendMission };
