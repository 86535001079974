<template>
  <el-form class="s-search-bar-wrapper" size="small" :inline="true" :model="schedule">
    <el-form-item label="站点名称">
      <el-select v-model="schedule.id" clearable @change="inputEquipmentId(schedule.id)">
        <el-option v-for="site in siteList" :value="site.siteID" :label="site.siteName" :key="site.siteID"></el-option>
      </el-select>
      <!-- <el-input placeholder="站点名称" @keyup.enter.native="inquire(schedule.id)"></el-input> -->
    </el-form-item>
    <el-form-item label="生效时间">
      <el-date-picker v-model="schedule.queryTimes" type="datetimerange" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" style="height: 34px" @change="changeDates"></el-date-picker>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" icon="el-icon-search" @click="inquire(schedule.id)">查询</el-button>
    </el-form-item>
    <el-form-item style="float: right">
      <el-button class="el-button" type="primary" @click="add()">新增计划</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'HSearchBar',
  props: {
    siteList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      schedule: {
        id: '',
        queryTimes: [],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },

  methods: {
    add() {
      this.$emit('add');
    },

    changeDates(t) {
      this.inquire();
    },
    inputEquipmentId(id) {
      this.inquire();
    },
    inquire() {
      this.$emit('inquire', this.schedule);
    },
  },
};
</script>
