import sleep from "@/utils/sleep"
/**
 * @description: 获取页面高度并且计算此高度的页面应当请求多少数据
 * @param {*}
 * @return {*}
 * @author: 篆愁君
 */
export default {
  name: 'Mixins',
  methods: {
    async getTBodyHeight(time) {
      await sleep(time)
      return Math.ceil(parseInt(document.getElementsByClassName('el-table__body-wrapper')[0].style.height) / 51)
    },
  }
}