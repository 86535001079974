<template>
  <el-form ref="PlanMForm" class="plan-form-wrapper" label-position="left" label-width="80px" :model="planInformations" :rules="rules">
    <el-row v-if="isEditor">
      <el-col :span="24">
        <el-form-item label="定时任务" prop="cascaderValue">
          <el-cascader style="width: 100%" v-model="planInformations.cascaderValue" :props="{ lazy: true, lazyLoad: lazyLoad }" @change="cascaderValueChange"></el-cascader>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="执行模式">
          <el-select v-model="planInformations.mode" :disabled="!isEditor">
            <el-option v-for="mode in models" :value="mode.value" :label="mode.label" :key="mode.key"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item class="timer-select" label="执行时间" prop="time">
          <el-time-picker :key="'time-picker'" value-format="HH:mm:ss" v-model="planInformations.time" placeholder="执行时间" style="width: 193px"></el-time-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item class="workspace-options-wrapper" label="选择日期" v-if="planInformations.mode === 3 || planInformations.mode === 4">
      <el-input :value="workspace" disabled>
        <el-button slot="append" :class="[showDateOptions ? 'el-icon-arrow-up' : 'el-icon-arrow-down']" @click="showDateOptions = !showDateOptions"></el-button>
      </el-input>
    </el-form-item>
    <el-form-item class="options-wrapper" v-if="showDateOptions">
      <el-row>
        <div style="position: relative" @click="dateClickHandler">
          <div class="workspace" v-if="showDateOptions && planInformations.mode == 3">
            <div class="dates">
              <div :class="{ dates_item: true, week: true, selected: planInformations.weekDay.includes(item) }" :data-date="index" v-for="(item, index) in weekDays" :key="'date__week' + index">{{ index | getWeekDayCn }}</div>
            </div>
            <div class="dates" style="height: 0"></div>
          </div>
          <div class="workspace fade" v-else-if="showDateOptions && planInformations.mode == 4">
            <div class="dates" v-for="(item, index) in dates" :key="'date_' + index">
              <div :class="{ dates_item: true, selected: planInformations.day.includes(itme1) }" :data-date="itme1" v-for="(itme1, idx) in item" :key="'date_idx_' + idx">{{ itme1 }}</div>
            </div>
          </div>
        </div>
      </el-row>
    </el-form-item>
    <el-form-item :label="planInformations.mode === 1 ? '执行日期' : '生效范围'" prop="excuteTime">
      <el-date-picker :key="'time-picker2'" value-format="yyyy-MM-dd" v-model="planInformations.excuteTime" type="date" placeholder="执行日期" v-if="planInformations.mode === 1"></el-date-picker>
      <el-date-picker style="width: 400px" v-model="planInformations.excuteTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" v-else></el-date-picker>
    </el-form-item>
    <el-form-item label="备注">
      <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="planInformations.content"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import { getMissionBySiteId } from '@/api/mission';
import statusInclude from '@/utils/statusCode';
import { queryProjectListByCpID } from '@/api/project.js';
import { getUserInformation, getUserCorporationsInformation } from '@/utils/auth.js';
import { getSiteGroup } from '@/api/site';
import { SITE_HIVE_TYPE } from '@/enum/SITE_HIVE_TYPE';

const week = {
  0: '周日',
  1: '周一',
  2: '周二',
  3: '周三',
  4: '周四',
  5: '周五',
  6: '周六',
};
export default {
  props: {
    isEditor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mission: '任务名称',
      projectMap: new Map(),
      CPList: [],
      missionList: [],
      siteList: [],
      CPID: '',
      CPMAP: new Map(),
      uId: '',
      showDateOptions: false,
      options: [],
      missions: [], //任务列表

      dates: [],
      weekDays: [],
      models: [
        { value: 1, label: '单次' },
        { value: 2, label: '每天' },
        { value: 3, label: '每周' },
        { value: 4, label: '每月' },
      ],

      //form data
      planInformations: {
        siteID: '',
        missionID: '',
        missionName: '',
        cascaderValue: '',
        mode: 1,
        time: '',
        excuteTime: '',
        weekDay: [],
        day: [],
        content: '',
      },

      rules: {
        cascaderValue: [{ required: true, message: '请选择任务', trigger: 'change' }],
        time: [{ required: true, message: '请选择时间', trigger: 'change' }],
        excuteTime: [{ required: true, message: '请选择日期', trigger: 'change' }],
      },
      planMissionInformations: {
        siteID: '',
        CMID: '',
        content: '',
        createTime: '',
        day: [],
        endDate: '',
        excuteTime: '',
        hour: 0,
        min: 0,
        missionID: '',
        missionName: '',
        mode: 1,
        siteID: '',
        startDate: '',
        state: 0,
        updateTime: '',
        weekDay: [],
      },
    };
  },
  filters: {
    getWeekDayCn(n) {
      const weekMap = week;
      return weekMap[n];
    },
  },

  created() {
    //CPList
    let u = getUserInformation();
    let CPList = getUserCorporationsInformation();
    console.log(CPList);
    if (u && u.userInformation && u.userInformation.userId) {
      this.uId = u.userInformation.userId;
      this.CPList = CPList;
      if (this.CPList.length == 1) {
        this.CPID = CPList[0].cpId;
      }
      for (let cp of CPList) {
        this.CPMAP.set(cp.cpId, cp);
      }
    }
  },
  // TODO 计划任务表格
  mounted() {
    let ops = [];
    for (let site of this.siteList) {
      let siteObj = { value: site.siteID, label: site.siteName };
      ops.push(siteObj);
    }
    this.options = ops;
  },
  computed: {
    isDisable() {
      return this.planInformations.siteID && this.isEditor;
    },
    workspace() {
      let dateCns = '';
      if (this.planInformations.mode === 3) {
        let dates = this.planInformations.weekDay;
        if (dates && dates.length > 0) {
          for (let i = 0; i < dates.length; i++) {
            let dayCn = week[i];
            dateCns = dateCns + dayCn;
            if (i != dates.length - 1) {
              dateCns = dateCns + ',';
            }
          }
        }
      } else if (this.planInformations.mode === 4) {
        let dates = this.planInformations.day;
        if (dates && dates.length > 0) {
          for (let i = 0; i < dates.length; i++) {
            let day = dates[i];
            dateCns = dateCns + day;
            if (dates.length != i + 1) {
              dateCns = dateCns + ',';
            }
          }
        }
      }
      return dateCns;
    },
  },
  methods: {
    clearCascaderValue() {
      //  this.projectMap.clear();
      this.cascaderValue = '';
      // this.missionName = '';
    },
    async lazyLoad(node, resolve) {
      const { level, root } = node;
      let nodes = [];
      if (this.CPList.length == 1) {
        if (root) {
          let res = await queryProjectListByCpID(this.CPList[0].cpId);
          if (res?.data) {
            for (let item of res.data) {
              let node = { value: item.pId, label: item.pName, leaf: false };
              nodes.push(node);
            }
          } else {
          }
        } else {
          if (level == 1 && node.value) {
            let res = await getSiteGroup(1, 500, node.value);
            if (res?.data?.list) {
              this.siteList = res.data.list;
              for (let item of res.data.list) {
                if (item.siteMode == SITE_HIVE_TYPE.SITEMODE.hiveAndRadioFrequencyBox) {
                  let node = { value: item.siteID, label: item.siteName, leaf: false };
                  nodes.push(node);
                }
              }
            } else {
              this.siteList = [];
            }
          } else if (level == 2 && node.value) {
            let res = await getMissionBySiteId(node.value);
            if (res?.data?.list) {
              this.missionList = res.data.list;
              for (let item of res.data.list) {
                this.projectMap.set(item.missionID, item.missionName);
                let node = { value: item.missionID, label: item.missionName, leaf: true };
                nodes.push(node);
              }
            } else {
              this.siteList = [];
            }
          }
        }
      } else {
        if (root) {
          for (let item of this.CPList) {
            let node = { value: item.cpId, label: item.cpName, leaf: false };
            nodes.push(node);
          }
        } else {
          if (level == 1) {
            let res = await queryProjectListByCpID(node.data.value);
            if (res?.data) {
              for (let item of res.data) {
                let node = { value: item.pId, label: item.pName, leaf: false };
                nodes.push(node);
              }
            } else {
            }
          } else if (level == 2) {
            let res = await getSiteGroup(1, 500, node.value);
            if (res?.data?.list) {
              this.siteList = res.data.list;
              for (let item of res.data.list) {
                let node = { value: item.siteID, label: item.siteName, leaf: true };
                nodes.push(node);
              }
            } else {
              this.siteList = [];
            }
          } else {
          }
        }
      }
      resolve(nodes);
    },

    cascaderValueChange(v) {
      console.log(this.projectMap);
      if (v.length > 0) {
        if (v.length == 2) {
          // this.missionForm.pId = v[0];
          this.planInformations.siteID = v[0];
          this.planInformations.missionID = v[1];
          this.planInformations.missionName = this.projectMap.get(v[1]);
        } else if (v.length == 3) {
          this.planInformations.siteID = v[1];
          this.planInformations.missionID = v[2];

          this.planInformations.missionName = this.projectMap.get(v[2]);
        } else {
        }
      }
    },

    // 通过调用resolve将子节点数据返回，通知组件数据加载完成
    async getProjectList(cpId) {
      this.projectList = [];
      const res = await queryProjectListByCpID(cpId);
      //   if (res?.data?.list) {
      //     this.projectList = res.data.list
      //   }
      if (res?.data) {
        this.projectList = res.data;
      }
    },
    formatArr(n) {
      const arr = [];
      for (let i = 0; i < n; i++) {
        arr.push(i);
      }
      return arr;
    },
    getDates() {
      let a = [];
      let index = 0;
      for (let i = 1; i <= 5; i++) {
        let b = [];

        for (let j = 1; j <= 7; j++) {
          b.push(++index);
          if (index >= 31) {
            break;
          }
        }
        a.push(b);
      }

      return a;
    },
    dateClickHandler(evt) {
      let target = evt.target;
      let dataset = target.dataset;
      if (target.className.indexOf('dates_item') > -1) {
        let date = parseInt(dataset.date);
        if (target.className.indexOf('selected') > -1) {
          // 取消选择
          target.className = target.className.replace(' selected', '');
          if (this.planInformations.mode == 3) {
            this.removeWeekDate(date);
          } else if (this.planInformations.mode == 4) {
            this.removeMonthDate(date);
          }
        } else {
          target.className = target.className + ' selected';
          if (this.planInformations.mode == 3) {
            this.planInformations.weekDay.push(date);
          } else if (this.planInformations.mode == 4) {
            this.planInformations.day.push(date);
          }
        }
      }
    },
    removeWeekDate(date) {
      let index = -1;
      let dates = this.planInformations.weekDay;
      if (dates && dates.length > 0) {
        for (let i = 0; i < dates.length; i++) {
          if (dates[i] == date) {
            index = i;
            break;
          }
        }
        if (index > -1) {
          this.planInformations.weekDay.splice(index, 1);
        }
      }
    },
    removeMonthDate(date) {
      let index = -1;
      let dates = this.planInformations.day;
      if (dates && dates.length > 0) {
        for (let i = 0; i < dates.length; i++) {
          if (dates[i] == date) {
            index = i;
            break;
          }
        }
        if (index > -1) {
          this.planInformations.day.splice(index, 1);
        }
      }
    },
    //格式化定时任务数据
    formatting() {
      let pm;
      this.$refs['PlanMForm'].validate((valid) => {
        if (valid) {
          const tmpPlanInformation = JSON.parse(JSON.stringify(this.planMissionInformations));
          const { time, mode, siteID, day, weekDay, excuteTime, missionID, content } = this.planInformations;
          const [hour, min] = time.split(':').map(Number);
          tmpPlanInformation.siteID |= siteID;
          if (mode !== 1 && excuteTime) {
            const [startDate, endDate] = excuteTime.map((item) => new Date(item).getTime() / 1000);
            tmpPlanInformation.startDate = startDate;
            tmpPlanInformation.endDate = endDate;
            tmpPlanInformation.excuteTime = -1;
          } else {
            tmpPlanInformation.excuteTime = new Date(excuteTime + ' ' + time).getTime() / 1000;
          }
          const [mission] = this.missions.filter((item) => item.missionID === missionID);
          tmpPlanInformation.siteID = siteID;
          tmpPlanInformation.missionName = this.planInformations.missionName;
          tmpPlanInformation.missionID = missionID;
          tmpPlanInformation.mode = mode;
          tmpPlanInformation.hour = hour;
          tmpPlanInformation.min = min;
          tmpPlanInformation.day = day;
          tmpPlanInformation.weekDay = weekDay;
          tmpPlanInformation.content = content;
          pm = tmpPlanInformation;
          if (mode == 1) {
            tmpPlanInformation.hour = -1;
            tmpPlanInformation.min = -1;
          }
        } else {
          pm = false;
        }
      });
      return pm;
    },
    async getCurrentSiteMission(siteId) {
      const { code, data } = await getMissionBySiteId(siteId);
      if (statusInclude(code)) {
        const { count, list } = data;
        this.missions = list;
      }
    },
    resetForm() {
      for (let key in this.planInformations) {
        this.planInformations[key] = '';
      }
      this.$refs.PlanMForm.resetFields();
    },
  },
  watch: {
    planMissionInformations: {
      deep: true,
      handler(planMission) {
        const { siteID, day, endDate, hour, min, missionName, missionID, mode, startDate, weekDay, excuteList, content } = JSON.parse(JSON.stringify(planMission));
        this.planInformations.siteID = siteID;
        this.planInformations.missionID = missionID;
        this.planInformations.missionName = missionName;
        this.planInformations.mode = mode;
        this.planInformations.weekDay = weekDay;
        this.planInformations.day = day;
        this.planInformations.content = content;
        this.planInformations.time = `${hour}:${min}:00`; // : this.$moment(excuteTime * 1000).format('YYYY-MM-DD HH:mm:ss');
        if (mode === 1) {
          if (excuteList) {
            const [{ excuteTime: et }] = excuteList;
            this.planInformations.excuteTime = this.$moment(et * 1000).format('YYYY-MM-DD');
            let date = new Date(et * 1000);
            this.planInformations.time = `${date.getHours()}:${date.getMinutes()}:00`;
          }
        } else {
          this.planInformations.excuteTime = [this.$moment(startDate * 1000).format('YYYY-MM-DD'), this.$moment(endDate * 1000).format('YYYY-MM-DD')];
        }
        siteID && this.getCurrentSiteMission(siteID);
      },
    },
    showDateOptions(newVal, oldVal) {
      newVal && (this[this.planInformations.mode == 3 ? 'weekDays' : 'dates'] = this.planInformations.mode == 3 ? this.formatArr(7) : this.getDates());
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-form-wrapper {
  ::v-deep .el-form-item {
    .el-form-item__content {
      .el-input-group--append {
        .el-input-group__append {
          background: #515151 !important;
          border-color: #{$borderSolid};
          .el-button {
            background: none !important;
          }
        }
      }
      .el-row {
        .workspace {
          position: absolute;
          width: 440px;
          // height: 100px;
          height: auto;
          display: flex;
          flex-direction: column;
          z-index: 100;
          background: #18273d;
          right: 0px;
          top: 0;
          border-radius: 3px;
          text-align: center;
          padding: 12px;
          user-select: none;
          .dates {
            margin: auto;
            height: 40px;
            font-size: 12px;
            cursor: pointer;
            width: 100%;
            display: flex;
            .dates_item {
              margin: auto;
              width: 40px;
              height: 40px;
              font-size: 12px;
              cursor: pointer;
              &:hover {
                opacity: 0.8;
              }
            }
          }
          .dates:last-child {
            width: 43%;
            left: 0;
            margin: 0;
          }
          .item {
            margin: auto;
            width: 40px;
            height: 40px;
            font-size: 12px;
            cursor: pointer;
          }
          .selected {
            background-color: #{$bActiveTest};
            color: white;
            border-radius: 4px;
          }
        }
      }
    }
  }
  .options-wrapper {
    padding-bottom: 20px;
  }
  ::v-deep .timer-select {
    .el-form-item__content .el-input {
      width: 177px;
    }
  }
}
</style>
