<template>
  <el-card class="schedule-page-wrapper page-container">
    <search-bar slot="header" :siteList="siteList" @inquire="inquire" @add="add"></search-bar>
    <TimerMissionList :scheduleMission="scheduleMission" :tHeight="tHeight" :siteList="siteList" @undockTimerPlan="undockTimerPlan" @handleEdit="handleEdit" />
    <PaginationBar :table-config="tableConfig" @change:pageNumber="handleCurrentChange" />
    <el-dialog :close-on-click-modal="false" title="定时任务" :visible.sync="dialogVisible" width="600px" :before-close="handleClose" @open="setCurrentValue" @close="resetPlanMissionForm">
      <SetPlanForm ref="PlanForm" :isEditor="isAdding" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitPlanMission">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import SearchBar from './components/SearchBar.vue';
import TimerMissionList from './components/TimerMissionList.vue';
import SetPlanForm from './components/SetPlanForm.vue';
import PaginationBar from '@/components/Pagination/index.vue';
import { _getTimerList, addTimer, deleteTimer, updateTimer } from '@/api/timer.js';
import { getSiteGroup } from '@/api/site.js';
import statusInclude from '@/utils/statusCode';
import Mixin from '@/views/deviceManagement/Mixin';
//TODO 需要修复计划任务BUG
export default {
  name: 'Schedule',
  mixins: [Mixin],
  components: {
    SearchBar,
    TimerMissionList,
    SetPlanForm,
    PaginationBar,
  },
  data() {
    return {
      tHeight: 0,
      dialogVisible: false,
      scheduleMission: [],
      siteList: [],
      missionList: [],
      params: {},
      isAdding: false,
      cronInfoModel: {
        CMID: '',
        content: '',
        createTime: '',
        day: [],
        endDate: 0,
        excuteTime: '',
        hour: 0,
        min: 0,
        missionID: '',
        missionName: '',
        mode: 1,
        siteID: '',
        startDate: 0,
        state: 0,
        updateTime: '',
        weekDay: [],
      },
      cronInfo: {},
      tableConfig: {
        page: 1,
        size: 20,
        total: 0,
      },
    };
  },
  created() {
    this.resetCronInfo();
    this.getSiteGroup();
    window.addEventListener('resize', this.getHeight);
    this.getHeight();
  },
  mounted() {
    this.getTimerList(this.tableConfig);
  },

  watch: {
    dialogVisible(t) {
      if (!t) {
        this.$refs.PlanForm.clearCascaderValue();
      }
    },
  },
  methods: {
    add() {
      this.resetCronInfo();
      this.$nextTick(() => {
        this.isAdding = true;
        this.dialogVisible = true;
      });
    },

    // 重置表单数据为原始数值
    resetCronInfo() {
      this.cronInfo = JSON.parse(JSON.stringify(this.cronInfoModel));
    },
    inquire(params) {
      let { id, queryTimes } = params;
      queryTimes = queryTimes || [];
      if (queryTimes.length == 2) {
        const [startDate, endDate] = queryTimes?.map((time) => this.$moment(time).format('YYYY-MM-DD HH:mm:ss'));
        this.params = { siteID: id, startDate, endDate };
        this.getTimerList({ ...this.tableConfig, startDate, endDate, siteID: id });
      } else {
        this.params = { siteID: id, startDate: '', endDate: '' };
        this.getTimerList({ ...this.tableConfig, ...this.params });
      }
    },

    /**
     * @description: 编辑定时任务
     * @param {*} cronInfo
     * @return {*}
     */
    handleEdit(cronInfo) {
      this.isAdding = false;
      this.cronInfo = cronInfo;
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    /**
     * @description: 获取窗口显示高度
     * @param {*}
     * @return {*}
     */
    getHeight() {
      this.tHeight = this.tHeight = document.getElementsByTagName('body')[0].offsetHeight - this.$store.state.settings.offset;
    },
    handleCurrentChange(page) {
      this.tableConfig.page = page;
      const { siteID, startDate, endDate } = this.params;
      this.getTimerList({ ...this.tableConfig, ...this.params });
    },

    //打开dialog窗口
    setCurrentValue() {
      this.$nextTick(() => {
        //copy计划任务信息
        this.$refs.PlanForm.planMissionInformations = JSON.parse(JSON.stringify(this.cronInfo));
      });
    },

    resetPlanMissionForm() {
      this.$refs.PlanForm.resetForm();
    },

    async getTimerList({ page, size, siteID = '', startDate = '', endDate = '', mode = '' }) {
      this.scheduleMission = [];
      const { code, data, reason } = await _getTimerList({ page, size, startDate, endDate, mode, siteID });

      if (statusInclude(code)) {
        const { list, total } = data;
        list.forEach((mission) => {
          mission?.excuteList.forEach((excute) => {
            excute.isDisabled = excute.isDisabled ? true : false;
          });
        });
        this.scheduleMission = list;
        this.tableConfig.total = total || this.tableConfig.total;
      } else {
        this.$message({ type: 'warning', message: reason });
      }
    },

    setuptimerMission(mission) {
      return new Promise(async (resolve) => {
        const response = await addTimer(mission);
        const result = {
          success: false,
          message: response.reason,
        };
        result.success = !statusInclude(response.code) ? false : true;
        return resolve(result);
      });
    },
    updatetimerMission(mission) {
      return new Promise(async (resolve) => {
        const response = await updateTimer(mission);
        const result = {
          success: false,
          message: response.reason,
        };
        result.success = !statusInclude(response.code) ? false : true;
        return resolve(result);
      });
    },

    /**
     * @description: 提交计划任务
     * @param {*}
     * @return {*}
     */
    async submitPlanMission() {
      const planMission = this.$refs.PlanForm.formatting();
      if (!planMission) return this.$message.error('请完整填写计划任务数据');
      let flag;
      if (this.isAdding) {
        const response = await this.setuptimerMission(planMission);
        const { success, message } = response;
        flag = success;
        !success && this.$message.error(message);
      } else {
        const response = await this.updatetimerMission(planMission);
        const { success, message } = response;
        flag = success;
        !success && this.$message.error(message);
      }
      if (!flag) return;
      this.$message({ type: 'success', message: '添加成功!' });
      this.dialogVisible = false;
      this.isAdding = false;
      this.getTimerList({ ...this.tableConfig, ...this.params });
    },
    undockTimerPlan(planId) {
      deleteTimer(planId)
        .then((res) => {
          this.$message({ type: 'success', message: '计划删除成功' });
          this.getTimerList({ ...this.tableConfig, ...this.params });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSiteGroup() {
      getSiteGroup().then((res) => {
        const {
          data: { count, list },
          code,
          result,
        } = res;
        this.siteList = list.map((site) => {
          return {
            siteID: site.siteID,
            siteName: site.siteName,
            disabled: site.siteMode === 1 ? false : true,
          };
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
