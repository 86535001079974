<template>
  <el-table
    class="schedule-table"
    :data="scheduleMission"
    style="width: 100%"
    :row-key="
      (row) => {
        return row.CMID;
      }
    "
    :expand-row-keys="expandRowKeys"
    :height="tHeight"
    @expand-change="expandChange"
  >
    <el-table-column label="序号" type="index" align="center" width="60"></el-table-column>
    <el-table-column type="expand" width="80" label="详情" align="center">
      <template slot-scope="props">
        <el-form class="demo-table-expand" label-width="120px" label-position="right" :inline="true">
          <el-form-item label="开始时间" v-if="props.row.startDate">
            <span>{{ $moment(props.row.startDate * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
          </el-form-item>
          <el-form-item label="结束时间" v-if="props.row.endDate">
            <span>{{ $moment(props.row.endDate * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
          </el-form-item>
          <el-form-item label="任务描述">
            <span>{{ props.row.content }}</span>
          </el-form-item>
        </el-form>
        <el-table :data="props.row.excuteList.slice((page - 1) * size, page * size)">
          <el-table-column label="编号" type="index" align="center" width="80px"></el-table-column>
          <el-table-column label="任务状态" align="center">
            <template slot-scope="scope">
              <el-tag size="mini" :type="scope.row.isExcute ? 'success' : 'warning'">{{ scope.row.isExcute ? '已执行' : '未执行' }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="执行时间" align="center">
            <template slot-scope="scope">
              <span>{{ $moment(scope.row.excuteTime * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="启用" align="center">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.isDisabled" active-color="#13ce66" inactive-color="#ff4949" active-text="禁用" inactive-text="启用" @change="handleEnablePlanMission(props.row.CMID, scope.row)"></el-switch>
            </template>
          </el-table-column>
        </el-table>
        <el-row style="padding-top: 10px">
          <el-pagination background layout="total, prev, pager, next" :total="props.row.excuteList.length" :current-page="page" :page-size="size" @current-change="handleCurrentChange"></el-pagination>
        </el-row>
      </template>
    </el-table-column>
    <el-table-column label="任务名称" prop="missionName"></el-table-column>
    <el-table-column label="站点名称" prop="siteName" align="center"> </el-table-column>
    <el-table-column label="航线任务编号" prop="missionID" align="center"></el-table-column>
    <el-table-column label="模式" prop="mode" align="center" width="100" :filters="modes" :filter-method="filterHandler">
      <template slot-scope="scope">
        <el-tag :color="scope.row.mode | scheduleMissionModeColor" size="mini">{{ scope.row.mode | scheduleMissionModeParse }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="开始时间" prop="startDate" align="center" width="120">
      <template slot-scope="scope">
        {{ scope.row.startDate !== 0 ? $moment(scope.row.startDate * 1000).format('YYYY-MM-DD') : '' }}
      </template>
    </el-table-column>
    <el-table-column label="结束时间" prop="endDate" align="center" width="120">
      <template slot-scope="scope">
        {{ scope.row.endDate !== 0 ? $moment(scope.row.endDate * 1000).format('YYYY-MM-DD') : '' }}
      </template>
    </el-table-column>
    <el-table-column label="执行时间" prop="excuteTime" align="center" width="120">
      <template slot-scope="scope">
        {{ scope.row.mode != 1 ? `${concatTimer(scope.row.hour)}:${concatTimer(scope.row.min)}:00` : $moment(scope.row.excuteTime * 1000).format('YYYY-MM-DD HH:mm:ss') }}
      </template>
    </el-table-column>
    <el-table-column label="状态" prop="state" align="center" width="100">
      <template slot-scope="scope">
        <el-tag size="mini" :type="scope.row.state ? '' : 'danger'">{{ scope.row.state ? '未执行' : '已执行' }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="创建时间" prop="createTime" align="center"></el-table-column>
    <el-table-column label="更新时间" prop="updateTime" align="center"></el-table-column>
    <el-table-column label="操作" align="center" width="147px">
      <template slot-scope="scope">
        <el-button type="text" icon="el-icon-delete" style="color: #f40" @click="undockTimerPlan(scope.row.CMID)">删除</el-button>
        <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.row)">修改</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { handleEnablePlanMission } from '@/api/timer.js';
import statusInclude from '../../../utils/statusCode';
export default {
  props: {
    tHeight: {
      type: Number,
      default() {
        return 0;
      },
    },
    scheduleMission: {
      type: Array,
      default() {
        return [];
      },
    },
    siteList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      expandRowKeys: [],
      page: 1,
      size: 4,
      modes: [
        { text: '单次', value: 1 },
        { text: '每天', value: 2 },
        { text: '每周', value: 3 },
        { text: '每月', value: 4 },
      ],
    };
  },

  created() {},

  mounted() {},
  methods: {
    handleEdit(row) {
      this.$emit('handleEdit', row);
    },

    expandChange(p1, p2) {
      this.page = 1;
      if (p2.length == 0) {
        this.expandRowKeys = [];
      } else {
        this.expandRowKeys = [p1.CMID];
      }

      // console.log(arguments);
    },
    async handleEnablePlanMission(CMID, row) {
      const { excuteTime, isDisabled } = row;
      const { code, reason } = await handleEnablePlanMission(CMID, excuteTime, isDisabled ? 1 : 0);
      if (!statusInclude(code)) return this.$message.error(`操作失败${reason}`);
      this.$message({ type: 'success', message: '请求成功' });
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    undockTimerPlan(CMID) {
      this.$confirm('此操作将删除计划任务, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$emit('undockTimerPlan', CMID);
        })
        .catch(() => {
          this.$message({ type: 'info', message: '已取消删除' });
        });
    },
    executionSite(siteId) {
      const site = this.siteList.find((site) => site.siteID === siteId);
    },
    executionMode(row) {
      switch (row.mode) {
        case 0:
          return this.$moment(row.excuteTime * 1000).format('YYYY-MM-DD HH:mm:ss');
        case 1:
          return `${this.$moment().format('YYYY-MM-DD')} ${this.concatTimer(row.hour)}:${this.concatTimer(row.min)}:00`;
        case 2:
          return `周${this.translationNumbar(row.weekDay)} — ${this.concatTimer(row.hour)}:${this.concatTimer(row.min)}:00`;
        case 3:
          return `${row.day}号 — ${this.concatTimer(row.hour)}:${this.concatTimer(row.min)}:00`;
      }
    },
    concatTimer(n) {
      if (n > 9) {
        return n;
      } else if (n < 0) {
        return `00`;
      } else {
        return `0${n}`;
      }
    },
    translationNumbar(num) {
      switch (num) {
        case 0:
          return '日';
        case 1:
          return '一';
        case 2:
          return '二';
        case 3:
          return '三';
        case 4:
          return '四';
        case 5:
          return '五';
        case 6:
          return '六';
      }
    },
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-table {
  :v-deep .el-table__body-wrapper {
    .el-table__body {
      tbody {
        tr.expanded + tr td.el-table__expanded-cell {
          background: #f40 !important;
          .el-row {
            .el-pagination {
              padding: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
